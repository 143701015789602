export default {
    name: "Department",
    data() {
        return {
            window: window,
            DepartmentListLeft: [{
                    url: require("../../assets/beijing.png"),
                    title: "北京碧莲盛医疗美容门诊部",
                    text: "北京碧莲盛医疗美容医院是隶属于碧莲盛集团的旗舰院部，坐落于北京市海淀区复兴路，占地面积三千多平方米，拥有以杨志祥教授为核心的医生团队，为多位明星、老艺术家及网红进行过植发服务。",
                    address: "地址：北京市海淀区复兴路甲36号百朗园207号",
                },

                {
                    url: require("../../assets/chengdu.png"),
                    title: "成都碧莲盛医疗美容医院",
                    text: "成都碧莲盛医疗美容医院坐落于成都市青羊区光华北三路，拥有冯文力、梁峰等业内知名医师，以及一支超过五十人的专业植发团队。",
                    address: "地址：成都市青羊区华北三路160号2层",
                },

                {
                    url: require("../../assets/jinan.png"),
                    title: "济南碧莲盛医疗美容医院",
                    text: "济南碧莲盛植发美容医院坐落在济南市历下区，拥有尹梓贻、赵亮、闫素素等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：济南市历下区趵突泉北路11号三连大厦北侧",
                },

                {
                    url: require("../../assets/kunming.png"),
                    title: "昆明碧莲盛医疗美容医院",
                    text: "昆明碧莲盛植发美容医院坐落在昆明市西山区，地段便利，医资雄厚拥有杨永华、江龙等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：昆明市西山区环城西路328号太阳城一层",
                },

                {
                    url: require("../../assets/xian.png"),
                    title: "西安碧莲盛医疗美容医院",
                    text: "西安碧莲盛植发美容医院位于西安市碑林区，建院时间久，经验丰富技术精湛，拥有张世超、马啸天等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：西安市碑林区南广济街83号",
                },

                {
                    url: require("../../assets/wuhan.png"),
                    title: "武汉碧莲盛医疗美容医院",
                    text: "武汉碧莲盛植发美容医院位于武汉市江汉区，拥有孙锐、汪贤文等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：武汉市江汉区马场路金贸大厦一层",
                },

                {
                    url: require("../../assets/guangzhou.png"),
                    title: "广州碧莲盛医疗美容门诊部",
                    text: "广州碧莲盛植发美容医院位于广州市天河区，拥有黄广程、鲁少康、尹卓维、周健等业内知名医师，以及一支经验丰富的专业植发团队",
                    address: "地址：广州市天河区天府路145号（天河公园正对面）",
                },

                {
                    url: require("../../assets/changsha.png"),
                    title: "长沙碧莲盛医疗美容医院",
                    text: "长沙碧莲盛植发美容医院坐落于长沙市开福区，拥有卢春涛、罗继森冯广飞等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：长沙市开福区芙蓉北路街道13388号恒大御景半岛40-41号",
                },

                {
                    url: require("../../assets/chongqing.png"),
                    title: "重庆碧莲盛医疗美容医院",
                    text: "重庆碧莲盛植发美容医院位于重庆市江北区，拥有李兴国、徐乐、李盼盼等业内知名医师，以及一支完善的植发团队。",
                    address: "地址：重庆市江北区金源路68号附46号",
                },

                {
                    url: require("../../assets/shenzhen.png"),
                    title: "深圳碧莲盛医疗美容医院",
                    text: "深圳碧莲盛植发美容医院位于深圳市福田区，拥有杨勇、贺俊、李自克等业内知名医师，以及一支富有活力朝气的植发团队。",
                    address: "地址：深圳市福田区滨河大道9013号嘉洲豪园裙楼第一层",
                },

                {
                    url: require("../../assets/zhengzhou.png"),
                    title: "郑州碧莲盛医疗美容医院",
                    text: "郑州碧莲盛植发美容医院坐落在郑州市金水区，拥有李世涛、史元培牛东兴等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：郑州市金水区东明路北260号院1号楼",
                },

                {
                    url: require("../../assets/shanghai.png"),
                    title: "上海碧莲盛医疗美容医院",
                    text: "上海碧莲盛医疗美容医院坐落于上海市普陀区，拥有陈燕华、张卓、周宗贵、张鹏程、王建辉等业内知名医师，实力雄厚，团队经验丰富，成功案例数不胜数。",
                    address: "地址：上海市普陀区澳门路618/620/622号",
                },
                {
                    url: require("../../assets/xining.png"),
                    title: "西宁碧莲盛医疗美容医院",
                    text: "西宁碧莲盛植发美容医院坐落在西宁市城中区，拥有以陈玉峰、孙雪玉等业内知名医师，以及一支完善的专业。",
                    address: "地址：西宁市城中区长江路53号53-15室",
                },
                {
                    url: require("../../assets/lanzhou.png"),
                    title: "兰州碧莲盛医疗美容门诊部",
                    text: "兰州碧莲盛植发美容医院坐落在兰州市城关区，地段便利，医资雄厚拥有韩伟、刘尚彦、蒋海龙等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：兰州市城关区张掖路街道酒泉路372-376号2层002室-01",
                },
                {
                    url: require("../../assets/zhuhai.png"),
                    title: "珠海碧莲盛医疗美容医院",
                    text: "珠海碧莲盛植发美容医院位于珠海市香洲区，拥有xxx等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：珠海市香洲区滨海南路68号201-B.202",
                },
                {
                    url: require("../../assets/haikou.png"),
                    title: "海口碧莲盛医疗美容医院",
                    text: "海口碧莲盛植发美容医院位于海口市龙华区，设施完善，经验丰富，技术精湛，拥有戴尚进、周金鑫等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：海口市龙华区海秀中路111-1号濠江花园A栋两层商场",
                },
                {
                    url: require("../../assets/dongguan.png"),
                    title: "东莞碧莲盛医疗美容医院",
                    text: "东莞碧莲盛植发美容医院坐落于东莞市南城，拥有xxx等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：东莞市南城街道体育路南城段30号",
                },
            ],

            DepartmentListRight: [

                {
                    url: require("../../assets/hangzhou.png"),
                    title: "杭州碧莲盛医疗美容门诊部",
                    text: "杭州碧莲盛植发美容医院位于杭州市西湖区，建院时间久，经验丰富，技术精湛，拥有张章、杨昕、杨勇华、王亚昆、陈高扬等业内知名医师以及一支完善的专业植发团队。",
                    address: "地址：杭州市西湖区文三路141号",
                },
                {
                    url: require("../../assets/fuzhou.png"),
                    title: "福州碧莲盛医疗美容医院",
                    text: "福州碧莲盛植发美容医院坐落在福州市鼓楼区，地段便利，医资雄厚拥有苏斌、李仙等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：福州市鼓楼区杨桥东路19号衣锦华庭一区",
                },
                {
                    url: require("../../assets/nanjing.png"),
                    title: "南京碧莲盛医疗美容医院",
                    text: "南京碧莲盛植发美容医院坐落于南京市鼓楼区，拥有史后彬、王荣等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：南京市鼓楼区东宝路8号02幢401室",
                },
                {
                    url: require("../../assets/shenyang.png"),
                    title: "沈阳碧莲盛医疗美容医院",
                    text: "沈阳碧莲盛植发美容医院位于沈阳市和平区，拥有郎振图、郭永芳等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：沈阳市和平区市府大路137号",
                },
                {
                    url: require("../../assets/xiamen.png"),
                    title: "厦门碧莲盛医疗美容医院",
                    text: "厦门碧莲盛植发美容医院位于厦门思明区，拥有徐昌凤、罗道龙等业内知名医师，以及一支完善的植发团队。",
                    address: "地址：厦门市思明区湖滨北路长青北里108号一单元",
                },
                {
                    url: require("../../assets/taiyuan.png"),
                    title: "太原碧莲盛医疗美容医院",
                    text: "太原碧莲盛植发美容医院位于太原市迎泽区，拥有朱世伟、李跟亮、黄晨等业内知名医师，以及一支经验丰富的专业植发团队。",
                    address: "地址：太原市迎泽区桥头街新月大厦一层、二层",
                },
                {
                    url: require("../../assets/nanning.png"),
                    title: "南宁碧莲盛医疗美容门诊部",
                    text: "南宁碧莲盛医疗美容医院坐落于南宁市青秀区，拥有林迪生、李冬丽等业内知名医师，以及一支超过五十人的专业植发团队。",
                    address: "地址：南宁市青秀区青秀路16-2号右江花园9号楼2层201",
                },
                {
                    url: require("../../assets/nanchang.png"),
                    title: "南昌碧莲盛医疗美容医院",
                    text: "南昌碧莲盛植发美容医院位于南昌市西湖区，拥有夏正法、伍湘涛、畅昭、付海隆等业内知名医师，以及一支富有活力朝气的植发团队。",
                    address: "地址：南昌市西湖区天佑路5号",
                },
                {
                    url: require("../../assets/dalian.png"),
                    title: "大连碧莲盛医疗美容医院",
                    text: "大连碧莲盛植发美容医院坐落在大连市中山区，地段便利，医资雄厚拥有苏琳、张伟等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：大连市中山区中山路124号渤海饭店2楼",
                },
                {
                    url: require("../../assets/hefei.png"),
                    title: "合肥碧莲盛医疗美容医院",
                    text: "合肥碧莲盛植发美容医院坐落在合肥市蜀山区，拥有刘举利、张辛宁等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：合肥市蜀山区环湖东路中天雅苑商办楼102/202",
                },
                {
                    url: require("../../assets/quanzhou.png"),
                    title: "泉州碧莲盛医疗美容医院",
                    text: "泉州碧莲盛植发美容医院位于泉州市丰泽区，拥有周翠谷、李伟君等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：泉州市丰泽区东海街道秀泉路与坪山路交叉路口",
                },
                {
                    url: require("../../assets/wulumuqi.png"),
                    title: "乌鲁木齐碧莲盛医疗美容医院",
                    text: "乌鲁木齐碧莲盛植发美容医院位于乌鲁木齐市沙依巴克区，建院时间久，经验丰富，技术精湛，拥有苏斌、王卜宣、贺朝晖等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：乌鲁木齐市沙依巴克区友好南路天隆大厦2层A室",
                },
                {
                    url: require("../../assets/shijiazhuang.png"),
                    title: "石家庄碧莲盛医疗美容门诊部",
                    text: "石家庄碧莲盛植发美容医院位于石家庄市新华区，拥有姜印、李志杰等业内知名医师，以及一支经验丰富的专业植发团队。",
                    address: "地址：石家庄市新华区康乐街8号尚德国际商务中心2层",
                },
                {
                    url: require("../../assets/ningbo.png"),
                    title: "宁波碧莲盛医疗美容医院",
                    text: "宁波碧莲盛植发美容医院坐落于宁波市江北区，拥有陈修义、文亚雄伍盛为等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：宁波市江北区人民北路178号同方杰座大厦1-3层",
                },
                {
                    url: require("../../assets/qingdao.png"),
                    title: "青岛碧莲盛医疗美容医院",
                    text: "青岛碧莲盛植发美容医院位于青岛市市南区，拥有李雪琴、张欣欣等业内知名医师，以及一支富有活力朝气的植发团队。",
                    address: "地址：青岛市市南区闽江路33号七单元3户",
                },
                {
                    url: require("../../assets/guiyang.png"),
                    title: "贵阳碧莲盛医疗美容医院",
                    text: "贵阳碧莲盛植发美容医院位于贵阳市南明区，拥有陈乐平、李丹丹等业内知名医师，以及一支完善的植发团队。",
                    address: "地址：贵阳市南明区公园中路花果园K区1栋203号房",
                },
                {
                    url: require("../../assets/suzhou.png"),
                    title: "苏州碧莲盛医疗美容医院",
                    text: "苏州碧莲盛医疗美容医院坐落于苏州市姑苏区，拥有李中先、孟文祥张小雷等业内知名医师，以及一支超过五十人的专业植发团队。",
                    address: "地址：苏州市姑苏区人民路3189号新平江商业广场一楼招商银行旁",
                },

            ],
            DepartmentListLeft1: [{
                    url: require("../../assets/beijing.png"),
                    title: "北京碧莲盛医疗美容门诊部",
                    text: "北京碧莲盛医疗美容医院是隶属于碧莲盛集团的旗舰院部，坐落于北京市海淀区复兴路，占地面积三千多平方米，拥有以杨志祥教授为核心的医生团队，为多位明星、老艺术家及网红进行过植发服务。",
                    address: "地址：北京市海淀区复兴路甲36号百朗园207号",
                },

                {
                    url: require("../../assets/chengdu.png"),
                    title: "成都碧莲盛医疗美容医院",
                    text: "成都碧莲盛医疗美容医院坐落于成都市青羊区光华北三路，拥有冯文力、梁峰等业内知名医师，以及一支超过五十人的专业植发团队。",
                    address: "地址：成都市青羊区华北三路160号2层",
                },

                {
                    url: require("../../assets/jinan.png"),
                    title: "济南碧莲盛医疗美容医院",
                    text: "济南碧莲盛植发美容医院坐落在济南市历下区，拥有尹梓贻、赵亮、闫素素等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：济南市历下区趵突泉北路11号三连大厦北侧",
                },

                {
                    url: require("../../assets/kunming.png"),
                    title: "昆明碧莲盛医疗美容医院",
                    text: "昆明碧莲盛植发美容医院坐落在昆明市西山区，地段便利，医资雄厚拥有杨永华、江龙等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：昆明市西山区环城西路328号太阳城一层",
                },

                {
                    url: require("../../assets/xian.png"),
                    title: "西安碧莲盛医疗美容医院",
                    text: "西安碧莲盛植发美容医院位于西安市碑林区，建院时间久，经验丰富技术精湛，拥有张世超、马啸天等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：西安市碑林区南广济街83号",
                },

                {
                    url: require("../../assets/wuhan.png"),
                    title: "武汉碧莲盛医疗美容医院",
                    text: "武汉碧莲盛植发美容医院位于武汉市江汉区，拥有孙锐、汪贤文等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：武汉市江汉区马场路金贸大厦一层",
                },

                {
                    url: require("../../assets/guangzhou.png"),
                    title: "广州碧莲盛医疗美容门诊部",
                    text: "广州碧莲盛植发美容医院位于广州市天河区，拥有黄广程、鲁少康、尹卓维、周健等业内知名医师，以及一支经验丰富的专业植发团队",
                    address: "地址：广州市天河区天府路145号（天河公园正对面）",
                },

                {
                    url: require("../../assets/changsha.png"),
                    title: "长沙碧莲盛医疗美容医院",
                    text: "长沙碧莲盛植发美容医院坐落于长沙市开福区，拥有卢春涛、罗继森冯广飞等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：长沙市开福区芙蓉北路街道13388号恒大御景半岛40-41号",
                },

                {
                    url: require("../../assets/chongqing.png"),
                    title: "重庆碧莲盛医疗美容医院",
                    text: "重庆碧莲盛植发美容医院位于重庆市江北区，拥有李兴国、徐乐、李盼盼等业内知名医师，以及一支完善的植发团队。",
                    address: "地址：重庆市江北区金源路68号附46号",
                },

                {
                    url: require("../../assets/shenzhen.png"),
                    title: "深圳碧莲盛医疗美容医院",
                    text: "深圳碧莲盛植发美容医院位于深圳市福田区，拥有杨勇、贺俊、李自克等业内知名医师，以及一支富有活力朝气的植发团队。",
                    address: "地址：深圳市福田区滨河大道9013号嘉洲豪园裙楼第一层",
                },

                {
                    url: require("../../assets/zhengzhou.png"),
                    title: "郑州碧莲盛医疗美容医院",
                    text: "郑州碧莲盛植发美容医院坐落在郑州市金水区，拥有李世涛、史元培牛东兴等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：郑州市金水区东明路北260号院1号楼",
                },

                {
                    url: require("../../assets/shanghai.png"),
                    title: "上海碧莲盛医疗美容医院",
                    text: "上海碧莲盛医疗美容医院坐落于上海市普陀区，拥有陈燕华、张卓、周宗贵、张鹏程、王建辉等业内知名医师，实力雄厚，团队经验丰富，成功案例数不胜数。",
                    address: "地址：上海市普陀区澳门路618/620/622号",
                },
                {
                    url: require("../../assets/xining.png"),
                    title: "西宁碧莲盛医疗美容医院",
                    text: "西宁碧莲盛植发美容医院坐落在西宁市城中区，拥有以陈玉峰、孙雪玉等业内知名医师，以及一支完善的专业。",
                    address: "地址：西宁市城中区长江路53号53-15室",
                },
                {
                    url: require("../../assets/lanzhou.png"),
                    title: "兰州碧莲盛医疗美容门诊部",
                    text: "兰州碧莲盛植发美容医院坐落在兰州市城关区，地段便利，医资雄厚拥有韩伟、刘尚彦、蒋海龙等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：兰州市城关区张掖路街道酒泉路372-376号2层002室-01",
                },
                {
                    url: require("../../assets/zhuhai.png"),
                    title: "珠海碧莲盛医疗美容医院",
                    text: "珠海碧莲盛植发美容医院位于珠海市香洲区，拥有xxx等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：珠海市香洲区滨海南路68号201-B.202",
                },
                {
                    url: require("../../assets/haikou.png"),
                    title: "海口碧莲盛医疗美容医院",
                    text: "海口碧莲盛植发美容医院位于海口市龙华区，设施完善，经验丰富，技术精湛，拥有戴尚进、周金鑫等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：海口市龙华区海秀中路111-1号濠江花园A栋两层商场",
                },
                {
                    url: require("../../assets/dongguan.png"),
                    title: "东莞碧莲盛医疗美容医院",
                    text: "东莞碧莲盛植发美容医院坐落于东莞市南城，拥有xxx等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：东莞市南城街道体育路南城段30号",
                },
            ],

            DepartmentListRight1: [

                {
                    url: require("../../assets/hangzhou.png"),
                    title: "杭州碧莲盛医疗美容门诊部",
                    text: "杭州碧莲盛植发美容医院位于杭州市西湖区，建院时间久，经验丰富，技术精湛，拥有张章、杨昕、杨勇华、王亚昆、陈高扬等业内知名医师以及一支完善的专业植发团队。",
                    address: "地址：杭州市西湖区文三路141号",
                },
                {
                    url: require("../../assets/fuzhou.png"),
                    title: "福州碧莲盛医疗美容医院",
                    text: "福州碧莲盛植发美容医院坐落在福州市鼓楼区，地段便利，医资雄厚拥有苏斌、李仙等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：福州市鼓楼区杨桥东路19号衣锦华庭一区",
                },
                {
                    url: require("../../assets/nanjing.png"),
                    title: "南京碧莲盛医疗美容医院",
                    text: "南京碧莲盛植发美容医院坐落于南京市鼓楼区，拥有史后彬、王荣等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：南京市鼓楼区东宝路8号02幢401室",
                },
                {
                    url: require("../../assets/shenyang.png"),
                    title: "沈阳碧莲盛医疗美容医院",
                    text: "沈阳碧莲盛植发美容医院位于沈阳市和平区，拥有郎振图、郭永芳等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：沈阳市和平区市府大路137号",
                },
                {
                    url: require("../../assets/xiamen.png"),
                    title: "厦门碧莲盛医疗美容医院",
                    text: "厦门碧莲盛植发美容医院位于厦门思明区，拥有徐昌凤、罗道龙等业内知名医师，以及一支完善的植发团队。",
                    address: "地址：厦门市思明区湖滨北路长青北里108号一单元",
                },
                {
                    url: require("../../assets/taiyuan.png"),
                    title: "太原碧莲盛医疗美容医院",
                    text: "太原碧莲盛植发美容医院位于太原市迎泽区，拥有朱世伟、李跟亮、黄晨等业内知名医师，以及一支经验丰富的专业植发团队。",
                    address: "地址：太原市迎泽区桥头街新月大厦一层、二层",
                },
                {
                    url: require("../../assets/nanning.png"),
                    title: "南宁碧莲盛医疗美容门诊部",
                    text: "南宁碧莲盛医疗美容医院坐落于南宁市青秀区，拥有林迪生、李冬丽等业内知名医师，以及一支超过五十人的专业植发团队。",
                    address: "地址：南宁市青秀区青秀路16-2号右江花园9号楼2层201",
                },
                {
                    url: require("../../assets/nanchang.png"),
                    title: "南昌碧莲盛医疗美容医院",
                    text: "南昌碧莲盛植发美容医院位于南昌市西湖区，拥有夏正法、伍湘涛、畅昭、付海隆等业内知名医师，以及一支富有活力朝气的植发团队。",
                    address: "地址：南昌市西湖区天佑路5号",
                },
                {
                    url: require("../../assets/dalian.png"),
                    title: "大连碧莲盛医疗美容医院",
                    text: "大连碧莲盛植发美容医院坐落在大连市中山区，地段便利，医资雄厚拥有苏琳、张伟等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：大连市中山区中山路124号渤海饭店2楼",
                },
                {
                    url: require("../../assets/hefei.png"),
                    title: "合肥碧莲盛医疗美容医院",
                    text: "合肥碧莲盛植发美容医院坐落在合肥市蜀山区，拥有刘举利、张辛宁等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：合肥市蜀山区环湖东路中天雅苑商办楼102/202",
                },
                {
                    url: require("../../assets/quanzhou.png"),
                    title: "泉州碧莲盛医疗美容医院",
                    text: "泉州碧莲盛植发美容医院位于泉州市丰泽区，拥有周翠谷、李伟君等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：泉州市丰泽区东海街道秀泉路与坪山路交叉路口",
                },
                {
                    url: require("../../assets/wulumuqi.png"),
                    title: "乌鲁木齐碧莲盛医疗美容医院",
                    text: "乌鲁木齐碧莲盛植发美容医院位于乌鲁木齐市沙依巴克区，建院时间久，经验丰富，技术精湛，拥有苏斌、王卜宣、贺朝晖等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：乌鲁木齐市沙依巴克区友好南路天隆大厦2层A室",
                },
                {
                    url: require("../../assets/shijiazhuang.png"),
                    title: "石家庄碧莲盛医疗美容门诊部",
                    text: "石家庄碧莲盛植发美容医院位于石家庄市新华区，拥有姜印、李志杰等业内知名医师，以及一支经验丰富的专业植发团队。",
                    address: "地址：石家庄市新华区康乐街8号尚德国际商务中心2层",
                },
                {
                    url: require("../../assets/ningbo.png"),
                    title: "宁波碧莲盛医疗美容医院",
                    text: "宁波碧莲盛植发美容医院坐落于宁波市江北区，拥有陈修义、文亚雄伍盛为等业内知名医师，以及一支完善的专业植发团队。",
                    address: "地址：宁波市江北区人民北路178号同方杰座大厦1-3层",
                },
                {
                    url: require("../../assets/qingdao.png"),
                    title: "青岛碧莲盛医疗美容医院",
                    text: "青岛碧莲盛植发美容医院位于青岛市市南区，拥有李雪琴、张欣欣等业内知名医师，以及一支富有活力朝气的植发团队。",
                    address: "地址：青岛市市南区闽江路33号七单元3户",
                },
                {
                    url: require("../../assets/guiyang.png"),
                    title: "贵阳碧莲盛医疗美容医院",
                    text: "贵阳碧莲盛植发美容医院位于贵阳市南明区，拥有陈乐平、李丹丹等业内知名医师，以及一支完善的植发团队。",
                    address: "地址：贵阳市南明区公园中路花果园K区1栋203号房",
                },
                {
                    url: require("../../assets/suzhou.png"),
                    title: "苏州碧莲盛医疗美容医院",
                    text: "苏州碧莲盛医疗美容医院坐落于苏州市姑苏区，拥有李中先、孟文祥张小雷等业内知名医师，以及一支超过五十人的专业植发团队。",
                    address: "地址：苏州市姑苏区人民路3189号新平江商业广场一楼招商银行旁",
                },

            ],

            //底部
            bottomList: [{
                    url: require("../../assets/16.svg"),
                    text: " 2005年创立企业",
                },
                {
                    url: require("../../assets/32.svg"),
                    text: " 全国直营连锁",
                },
                {
                    url: require("../../assets/13.svg"),
                    text: " 13年连续参加世界植发大会",
                },
                {
                    url: require("../../assets/600.svg"),
                    text: " 600位医护团队",
                },
                {
                    url: require("../../assets/300.svg"),
                    text: " 16类植养方式",
                },
            ],
        };
    },
};